* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  color: #2F328A;
}

h1 {
  color: #2F328A;
}

h1 {
  color: #2F328A;
}

h1 {
  color: #2F328A;
}

h5 {
  color: #fab615;
}

.viewPassword, .deleteField, .searchField {
  padding: 0.39rem 0.70rem !important;
  border-radius: 0 25% 25% 0 !important;
  height: 100%;
}

.cleanField {
  padding: 0.39rem 0.70rem !important;
  border-radius: 0 0 0 0 !important;
  height: 100%;
}

.textareCampo {
  height: 100px !important;
}

/* Loader */
.contenedor_loader {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(1,1,1,0.6);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contenedor_animacion {
  margin-top: -20px;
}

/* Breadcrumb */
.breadcrumb-item + .breadcrumb-item::before {
	color: #9e9e9e;
	font-size: medium;
	content: ">" !important;
}

.breadcrumb-item {
	font-size: medium;
	font-weight: 700;
}

.bread-active{
	color: #2F328A;
}

.bread-link{
	color: #9e9e9e;
	text-decoration: none;
}

/* Select */
.select-group {
  width: 100%;
}

.react-select__control {
  height: 100%;
}

.contenedor-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

/* Datepicker */
.input-datepícker {
  padding: 1rem 0.75rem;
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.label-datepicker {
  opacity: .65;
  transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}

/* Timepicker */
.react-time-picker__wrapper {
  border: transparent!important;
}

/* Colores */
/* --bs-blue: #0d6efd;
--bs-indigo: #6610f2;
--bs-purple: #6f42c1;
--bs-pink: #d63384;
--bs-red: #dc3545;
--bs-orange: #fd7e14;
--bs-yellow: #ffc107;
--bs-green: #198754;
--bs-teal: #20c997;
--bs-cyan: #0dcaf0;
--bs-black: #000;
--bs-white: #fff;
--bs-gray: #6c757d;
--bs-gray-dark: #343a40;
--bs-gray-100: #f8f9fa;
--bs-gray-200: #e9ecef;
--bs-gray-300: #dee2e6;
--bs-gray-400: #ced4da;
--bs-gray-500: #adb5bd;
--bs-gray-600: #6c757d;
--bs-gray-700: #495057;
--bs-gray-800: #343a40;
--bs-gray-900: #212529;
--bs-primary: #0d6efd;
--bs-secondary: #6c757d;
--bs-success: #198754;
--bs-info: #0dcaf0;
--bs-warning: #ffc107;
--bs-danger: #dc3545;
--bs-light: #f8f9fa;
--bs-dark: #212529;
--bs-primary-rgb: 13,110,253;
--bs-secondary-rgb: 108,117,125;
--bs-success-rgb: 25,135,84;
--bs-info-rgb: 13,202,240;
--bs-warning-rgb: 255,193,7;
--bs-danger-rgb: 220,53,69;
--bs-light-rgb: 248,249,250;
--bs-dark-rgb: 33,37,41;
--bs-white-rgb: 255,255,255;
--bs-black-rgb: 0,0,0;
--bs-body-color-rgb: 33,37,41;
--bs-body-bg-rgb: 255,255,255;
--bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
--bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
--bs-body-font-family: var(--bs-font-sans-serif);
--bs-body-font-size: 1rem;
--bs-body-font-weight: 400;
--bs-body-line-height: 1.5;
--bs-body-color: #212529;
--bs-body-bg: #fff;
--bs-border-width: 1px;
--bs-border-style: solid;
--bs-border-color: #dee2e6;
--bs-border-color-translucent: rgba(0, 0, 0, 0.175);
--bs-border-radius: 0.375rem;
--bs-border-radius-sm: 0.25rem;
--bs-border-radius-lg: 0.5rem;
--bs-border-radius-xl: 1rem;
--bs-border-radius-2xl: 2rem;
--bs-border-radius-pill: 50rem;
--bs-link-color: #0d6efd;
--bs-link-hover-color: #0a58ca;
--bs-code-color: #d63384;
--bs-highlight-bg: #fff3cd; */

/* Botones */
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #464ac0;
  --bs-btn-border-color: #464AC0;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2F328A;
  --bs-btn-hover-border-color: #fab615;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fab615;
  --bs-btn-active-border-color: #420C8E;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #464AC0;
  --bs-btn-disabled-border-color: #464AC0;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #879A04;
  --bs-btn-border-color: #879A04;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5C6803;
  --bs-btn-hover-border-color: #5C6803;
  --bs-btn-focus-shadow-rgb: 60,153,110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5C6803;
  --bs-btn-active-border-color: #5C6803;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #879A04;
  --bs-btn-disabled-border-color: #879A04;
}

.btn-outline-primary {
  --bs-btn-color: #fab615;
  --bs-btn-border-color: #fab615;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fab615;
  --bs-btn-hover-border-color: #fab615;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fab615;
  --bs-btn-active-border-color: #fab615;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fab615;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fab615;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #5C6803;
  --bs-btn-border-color: #5C6803;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5C6803;
  --bs-btn-hover-border-color: #5C6803;
  --bs-btn-focus-shadow-rgb: 25,135,84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5C6803;
  --bs-btn-active-border-color: #5C6803;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5C6803;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5C6803;
  --bs-gradient: none;
}

.btn-check:checked+.btn {
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fab615;
  --bs-btn-active-border-color: #fab615;
}

/* Navegaciones */
.nav {
  --bs-link-color: #2F328A;
  --bs-link-hover-color: #464AC0;
  --bs-nav-pills-link-active-bg: #2F328A;
}

/* Textos */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(70, 74, 192, 1) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(17, 131, 172, 1) !important;
}
 
.text-warning {
  --bs-text-opacity: 1;
  color: rgba(204, 158, 0, 1) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(154, 0, 84, 1) !important;
}

/* Fondos */
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgb(0 0 0) !important;
}

/* Datepicker */
.react-datepicker-wrapper {
  width: 100%;
}

/* Tab */
#dias-tab {
  min-width: 100px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #000000 !important;
  background-color: #fab615 !important;
}